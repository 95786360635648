<template>
  <div>
    <b-card title="Not much to see on this page (yet)">
      <b-card-text>This page is still under construction.</b-card-text>
    </b-card></div>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
